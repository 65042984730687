import React from "react";
import "./download.css"
import { BsInfoCircleFill } from "react-icons/bs";
import { IoMdCloudDownload } from "react-icons/io";

const Download = () => {

    const downloadPDFFile_adult = () => {
        const pdfUrl = "https://www.wolfenbuettel-kieferorthopaedie.de/Anamnesebogen_Erwachsene.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Anamnesebogen_Erwachsene.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadPDFFile_kids = () => {
        const pdfUrl = "https://www.wolfenbuettel-kieferorthopaedie.de/Anamnesebogen_Kinder.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Anamnesebogen_Kinder.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="download_headline">
                <div className="download__headline">
                    <h1>
                        Anamnesebogen
                    </h1>
                </div>
            </div>

            <div className="download__intro">
                <div className="download___intro">
                    <div className="download____intro">
                        <div className="infoIcon_colorBox">
                            <BsInfoCircleFill className="informationBox" />
                        </div>
                        <div>
                            <p>
                                In unserer Praxis sind wir stets bestrebt einen für Sie möglichst
                                reibungslosen Ablauf zu ermöglichen und die Wartezeiten
                                für alle Patienten so gering wie möglich zu halten. Daher bitten wir Sie als Neupatienten
                                den Anamnesebogen für sich oder Ihr Kind vorab auszufüllen und uns per Mail zu schicken oder ausgedruckt
                                zum Erstberatungstermin mitzubringen. Vielen Dank für Ihre Mithilfe.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="download_container">
                <div className="download__container">

                    <div className="download__container_adult">
                        <h2>
                            Anamnesebogen für Erwachsene
                        </h2>

                        <p>
                            Bitte laden Sie sich diesen Anamnesebogen herunter,
                            wenn Sie Neupatient und volljährig sind und keine Unterschrift eines
                            Erziehungsberechtigten benötigen.
                        </p>

                        <button title="downloadAnamnesebogen" className="download_btn" onClick={downloadPDFFile_adult}>
                            <IoMdCloudDownload className="downloadButtomStyle" /> Download PDF
                        </button>
                    </div>

                    <div className="download__container_kids">
                        <h2>
                            Anamnesebogen für Kinder
                        </h2>

                        <p>
                            Bitte laden Sie sich diesen Anamnesebogen herunter,
                            wenn Sie Neupatient und volljährig sind und keine Unterschrift eines
                            Erziehungsberechtigten benötigen.
                        </p>

                        <button title="downloadAnamnesebogen" className="download_btn" onClick={downloadPDFFile_kids}>
                            <IoMdCloudDownload className="downloadButtomStyle" /> Download PDF
                        </button>
                    </div>

                </div>

            </div>

            <div className="genContainerFooter">
                <div className="genContainerFooter_inner">
                    <p>
                        Wir Danken Ihnen für die Mithilfe!
                    </p>
                </div>
            </div>


        </>
    )
}

export default Download