import React, { useState } from "react";
import "./navbar.css";
/*import "./snow.scss";*/
import logo from "../../assets/Praxislogo3.png"
import { Sling as Hamburger } from 'hamburger-react'
import "animate.css"
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const Menu = () => (
    <>
        <p><NavLink to="/Home"> Home </NavLink></p>
        <p><NavLink to="/Praxis"> Praxis </NavLink></p>
        <p><NavLink to="/Team"> Team </NavLink></p>
        <p><NavLink to="/Leistungen"> Leistungen </NavLink></p>
        <p><NavLink to="/Kontakt"> Kontakt </NavLink></p>
        <p><NavLink to="/Stellenausschreibungen"> Stellenausschreibungen </NavLink></p>
        <p><NavLink to="/Download"> Anamnesebogen </NavLink></p>
    </>
)

const Navbar = () => {

    /* defining states of hamburger icon */
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <nav id="navigationBar" className="navbar" style = {{height:"120px"}}>
                <div className="navbar__logo">
                    <a href="/Home"><img id="navbarLogo" className="pngimage" loading="lazy" src={logo} alt="Praxislogo" style={{height: "130px"}} /></a>
                </div>

                <div className="navbar__links">
                    <div className="navbar__links_container">
                        <Menu />
                    </div>
                    <button title="Onlinetermine" className="onlineBookingNavbar" onClick={window.toggleDrFlexAppointments}>
                        <div className="onlineTxtDiv">
                            <span>Termin online buchen</span>
                            <div className="wave"></div>
                        </div>
                    </button>
                </div>

                <div className="hamburger_container" onClick={() => setOpen((prev) => !prev)}>
                    <Hamburger className="hamburger_button" size={30} rounded duration={0.4} color="white"> </Hamburger>
                </div>
            </nav>

            {isOpen ?
                <div id="mobile_nav" className="hamburger_menu animate__animated animate__fadeInRight">
                    <p><a href="/Home"> Home </a></p>
                    <p><a href="/Praxis"> Praxis </a></p>
                    <p><a href="/Team"> Team </a></p>
                    <p><a href="/Leistungen"> Leistungen </a></p>
                    <p><a href="/Kontakt"> Kontakt </a></p>
                    <p><a href="/Stellenausschreibungen"> Stellenausschreibungen </a></p>
                    <p><a href="/Download"> Anamnesebogen </a></p>
                    <button title="onlineBooking" className="onlineBookingNavbar" onClick={window.toggleDrFlexAppointments}>
                        <div className="onlineTxtDiv">
                            <span>Termin online buchen</span>
                            <div className="wave"></div>
                        </div>
                    </button>
                    {/*<div className="snow">
                </div>*/}
                </div>
                : ''

            }

        </>
    )
}

window.onclick = function () { scrollFunction() };
window.onscroll = function () { scrollFunction() };


function scrollFunction() {
    var navigationBar = document.getElementById("navigationBar");
    var navbarLogo = document.getElementById("navbarLogo");
    var mobileNav = document.getElementById("mobile_nav");

    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        if (navigationBar != null) {
            navigationBar.style.paddingTop = "10px";
            navigationBar.style.paddingBottom = "10px";
            navigationBar.style.height = "65px";
        }
        if (navbarLogo != null) navbarLogo.style.height = "70px";
        if (mobileNav != null) mobileNav.style.marginTop = "-185px";
    }
    else {
        if (navigationBar != null) {
            navigationBar.style.paddingTop = "25px";
            navigationBar.style.paddingBottom = "25px";
            navigationBar.style.height = "120px";
        }
        if (navbarLogo != null) navbarLogo.style.height = "130px";
        if (mobileNav != null) mobileNav.style.marginTop = "-100px";
    }
}

export default Navbar